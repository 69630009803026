<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Details of {{ location.name }} </CCardTitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CInput name="name" label="Name" placeholder="Enter the name of the location"
                      v-model="location.name" required />
                  </CCol>
                  <CCol sm="6">
                    <CInput name="website" label="Website" placeholder="Enter the website of the location"
                      v-model="location.website" />
                  </CCol>
                  <CCol sm="6">
                    <CInput name="name" label="CoC Nr" placeholder="Enter the Chamber of Commerce NR of the location"
                      v-model="location.coc_nr" required />
                  </CCol>
                  <CCol sm="6">
                    <CInput name="name" label="Tax Nr" placeholder="Enter the tax number of the location"
                      v-model="location.tax_nr" required />
                  </CCol>
                  <CCol sm="12">
                    <CTextarea name="note" label="Note" placeholder="Enter a note for the location"
                      v-model="location.note" />
                  </CCol>
                  <CCol sm="6">
                    <CInputFile name="image" label="Image" description="Maximum total upload size is 1MB.
                    Images can be compressed using: imagecompressor.com" v-on:change="setImage" required />
                    <img v-if="location.image" class="w-50" :src="storageURL + location.image" aria-busy="" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Contact Information</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="4">
                    <CInput name="address" label="Address" placeholder="Enter the address of the location"
                      v-model="location.location_address" />
                  </CCol>
                  <CCol sm="4">
                    <CInput label="Zip Code" placeholder="Enter the zip code of the location"
                      v-model="location.location_zip_code" />
                  </CCol>
                  <CCol sm="4">
                    <CInput label="City" placeholder="Enter the city of the location"
                      v-model="location.location_city" />
                  </CCol>
                  <CCol sm="4">
                    <CInput label="Country" placeholder="Enter the country of the location"
                      v-model="location.location_country" />
                  </CCol>
                  <CCol sm="4">
                    <CInput name="mail" label="Mail" placeholder="Enter the mail of the location"
                      v-model="location.location_mail" />
                  </CCol>
                  <CCol sm="4">
                    <CInput name="telephone" label="Telephone" placeholder="Enter the telephone of the location"
                      v-model="location.location_telephone" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Invoicing Information</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="4">
                    <CInput name="address" label="Address" placeholder="Enter the address of the location"
                      v-model="location.invoice_address" />
                  </CCol>
                  <CCol sm="4">
                    <CInput label="Zip Code" placeholder="Enter the zip code of the location"
                      v-model="location.invoice_zip_code" />
                  </CCol>
                  <CCol sm="4">
                    <CInput label="City" placeholder="Enter the city of the location" v-model="location.invoice_city" />
                  </CCol>
                  <CCol sm="4">
                    <CInput label="Country" placeholder="Enter the country of the location"
                      v-model="location.invoice_country" />
                  </CCol>
                  <CCol sm="4">
                    <CInput name="mail" label="Mail" placeholder="Enter the mail of the location"
                      v-model="location.invoice_mail" />
                  </CCol>
                  <CCol sm="4">
                    <CInput name="telephone" label="Telephone" placeholder="Enter the telephone of the location"
                      v-model="location.invoice_telephone" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Customers</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="12">
                    <CTextarea name="customers" placeholder="Enter the numbers or names of the customers"
                      description="If you separate values with comma's, customers will be duplicated. E.g. 123A,456B will create two rooms."
                      v-model="location.customers" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Terms & Conditions</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="12">
                    <ckeditor :editor="editor" v-model="location.terms" :config="editorConfig"></ckeditor>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Price Agreements</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="4" class="location-return-time">
                    <label class="datetime-label">Return Time</label>
                    <datetime format="HH:mm" type="time" placeholder="Select time" label="From"
                      v-model="location.return_time">
                      <template slot="before">
                        <CButton class="clear-btn" variant="outline" type="submit" size="sm" color="danger"
                          @click="location.return_time = null">
                          <CIcon name="cil-ban" />
                        </CButton>
                      </template>
                    </datetime>
                    <div style="margin-bottom: 20px"></div>
                  </CCol>
                </CRow>

                <div v-for="(price_agreement, index) in location.price_agreements">
                  <CRow>
                    <CCol sm="12">
                      <h5>{{ price_agreement.product_type }} Pricing</h5>
                    </CCol>
                    <CCol sm="3">
                      <CInput name="price_three_hour" label="Three Hour" prepend="€" type="number"
                        v-model="price_agreement.price_three_hours" min="0" />
                    </CCol>
                    <CCol sm="3">
                      <CInput prepend="€" name="price_five_hour" label="Five Hour" type="number"
                        v-model="price_agreement.price_five_hours" min="0" />
                    </CCol>
                    <CCol sm="3">
                      <CInput name="price_one_day" label="One Day" type="number" prepend="€"
                        v-model="price_agreement.price_one_day" min="0" />
                    </CCol>
                    <CCol sm="3">
                      <CInput name="price_extra_day" label="Extra Days" type="number" prepend="€"
                        v-model="price_agreement.price_extra_day" min="0" />
                    </CCol>
                    <CCol sm="3">
                      <CInput name="tax_percentage" label="Tax %" type="number" prepend="%"
                        v-model="price_agreement.tax_percentage" min="0" />
                    </CCol>
                    <CCol sm="3">
                      <CInput name="insurance" label="Insurance" type="number" prepend="€"
                        v-model="price_agreement.price_insurance" min="0" />
                    </CCol>
                    <CCol sm="3">
                      <CInput name="deal" label="Deal %" prepend="%" type="number"
                        v-model="price_agreement.deal_percentage" min="0" />
                    </CCol>
                    <CCol sm="3">
                      <label for="" class="">Insurance Optional</label><br />
                      <CSwitch label="Insurance" v-bind="{ variant: '3d', shape: 'pill', size: 'lg' }" color="success"
                        :checked.sync="price_agreement.insurance_optional" variant="outline" />
                    </CCol>
                  </CRow>

                  <hr />
                </div>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardTitle>Mews koppeling</CCardTitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CInput name="mews_access_token" label="Access token" placeholder="Enter the mews access token"
                      v-model="location.mews_access_token" />
                  </CCol>
                  <CCol sm="6" v-if="location.mews_access_token">
                    <label>Mews Service</label>
                    <v-select v-model="location.mews_service_id" :reduce="(service) => service.Id" label="Name"
                      :options="mews_services"></v-select>
                  </CCol>
                  <CCol sm="6">
                    <CInput name="mews_tax_code" label="Tax Code" placeholder="Enter the tax code for the products"
                      v-model="location.mews_tax_code" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCol sm="12">
              <CButton variant="outline" type="submit" color="primary" @click="updateLocation">
                <CIcon name="cil-check-circle" /> Update
              </CButton>
              <CButton class="float-right" variant="outline" type="submit" color="danger" @click="deleteLocation">
                <CIcon name="cil-trash" /> Delete
              </CButton>
            </CCol>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import { Datetime } from "vue-datetime"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import "vue-datetime/dist/vue-datetime.css"

import LocationAPI from "/app/src/api/location.js"
import MewsAPI from "/app/src/api/mews.js"

export default {
  name: "Detail",
  components: { Datetime },
  data: () => {
    return {
      location: {},
      image: {},
      locationAPI: new LocationAPI(),
      mewsAPI: new MewsAPI(),
      storageURL: process.env.VUE_APP_STORAGE_URL,
      mews_services: [],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "bold",
          "italic",
          "|",
          "link",
          "blockquote",
          "inserttable",
          "|",
          "bulletedlist",
          "numberedlist",
          "|",
          "undo",
          "redo",
        ],
      },
    }
  },
  created: function () {
    this.getLocation(this.$route.params.id)
  },
  methods: {
    getLocation: function (id) {
      this.$store.dispatch("loading")
      let self = this
      self.locationAPI
        .detail(id)
        .then((location) => {
          this.$store.dispatch("stopLoading")
          self.location = location
          this.initializePriceAgreements()
          if (location.mews_access_token) {
            this.getMewsServices()
          }
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          console.log(error)
          this.$alert.show({ type: "danger", message: error })
        })
    },
    getMewsServices: function () {
      console.log("get mews services")

      this.$store.dispatch("loading")

      let self = this
      this.mewsAPI
        .services(this.location.id)
        .then((services) => {
          this.$store.dispatch("stopLoading")
          self.mews_services = services
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({ type: "danger", message: error })
        })
    },
    initializePriceAgreements() {
      // Verify if all types are available
      let requiredPriceAgreements = [
        {
          product_type: "bicycle",
          matched: false,
        },
        {
          product_type: "accessories",
          matched: false,
        },
        {
          product_type: "e-bike",
          matched: false,
        },
      ]
      // Check missing price agreements
      for (let i = 0; i < requiredPriceAgreements.length; i++) {
        for (let j = 0; j < this.location.price_agreements.length; j++) {
          if (
            this.location.price_agreements[j].product_type ==
            requiredPriceAgreements[i].product_type
          ) {
            requiredPriceAgreements[i].matched = true
          }
        }

        if (requiredPriceAgreements[i].matched != true) {
          this.location.price_agreements.push({
            product_type: requiredPriceAgreements[i].product_type,
            tax_percentage: 21,
            insurance_optional: true,
          })
        }
      }
    },
    updateLocation: function () {
      this.$store.dispatch("loading")
      console.log(this.location)
      let self = this
      this.locationAPI
        .update(self.location, this.image)
        .then((result) => {
          this.$store.dispatch("stopLoading")
          document.body.scrollTop = 0
          this.$alert.show({
            type: "success",
            message: "Location has been updated",
          })
        })
        .catch((error) => {
          console.log(error)
          this.$alert.show({ type: "danger", message: error })
        })
    },
    deleteLocation: function () {
      const params = {
        title: "Confirm",
        text: "Are you sure you want to delete?",
        onConfirm: () => {
          this.$store.dispatch("loading")
          this.locationAPI
            .delete(this.$route.params.id)
            .then((result) => {
              this.$store.dispatch("stopLoading")
              console.log(result)
              this.$router.push({ path: "/manager/locations" })
            })
            .catch((error) => {
              console.log(error)
              this.$store.dispatch("stopLoading")
              this.$alert.show({ type: "danger", message: error })
            })
        },
      }

      this.$confirm.show(params)
    },
    setImage: function (files, e) {
      this.image = files[0]
    },
    deleteImage: function () {
      this.location.image = ""
    },
  },
}
</script>
